<template>
  <div class="error-container">
    <div class="error-info-box error-info-box2" v-if="textStatus === '2'">
      <h3>{{ $t("L_ERROR.ERROR_0005") }}</h3>
      <p>{{ $t("L_ERROR.ERROR_0006") }}</p>
      <div>
        <router-link to="/index" tag="span">{{
          $t("L_ERROR.ERROR_0004")
        }}</router-link>
      </div>
    </div>
    <div class="error-info-box error-info-box1" v-else>
      <h3>{{ $t("L_ERROR.ERROR_0001") }}</h3>
      <p>{{ count }}{{ $t("L_ERROR.ERROR_0002") }}</p>
      <div>
        <router-link to="/login" tag="span">{{
          $t("L_ERROR.ERROR_0003")
        }}</router-link>
        <router-link to="/index" tag="span">{{
          $t("L_ERROR.ERROR_0004")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlParam } from "@/utils";
export default {
  name: "error",
  data() {
    return {
      textStatus: null,
      timer: null,
      count: 5,
    };
  },
  mounted() {
    let state = getUrlParam("state"); //参数名state
    this.textStatus = state;
    this.$nextTick(() => {
      if (this.textStatus !== "2") {
        this.handleStore(null, "");
        this.handleTime();
      }
    });
  },
  methods: {
    handleTime() {
      this.count = 5;
      this.timer = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          this.$router.push({ path: "/login" });
        }
      }, 1000);
      this.$once("hook:beforeDestroy", () => {
        this.count = 5;
        clearInterval(this.timer);
        this.timer = null;
      });
    },
    handleStore(token, info) {
      this.$store.commit("setToken", token);
      this.$store.commit("setUserInfo", info);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/error";
</style>
